import React from "react";
import getContents from "./startlist";

function Startlisten(){
    return(
        <div>
            <div className="contentContainer">
                <h1 id="title" className="centered"> Startlisten </h1>
                <div className="content">

                    <div className="contentCenter">
                        <table>
                            <tr><td className="centered">Startliste komplett</td><td className="centered"><a href="Startliste_Genthin_2023_230902_193924.pdf" target="_blank">Download</a></td></tr>
                            <tr><td colSpan={2}><br/></td></tr>
                            <tr><td className='centered'>Männer Elite</td><td><a href='http://static.rad-net.de/html/bdr/meisterschaften/23-bdr/strasse/dm-mzf-elite-m-starter.pdf' target="_blank">Zur Startliste</a></td></tr>
                            <tr><td className='centered'>Rad-Bundesliga Frauen</td><td className="centered">---</td></tr>  
                            <tr><td className='centered'>Rad-Bundesliga Junioren</td><td className="centered">---</td></tr>     
                            <tr><td className='centered'>Rad-Bundesliga Juniorinnen</td><td className="centered">---</td></tr>                               
                            <tr><td className='centered'>Jugend U17</td><td><a href='http://static.rad-net.de/html/bdr/meisterschaften/23-bdr/strasse/dm-mzf-jugend-starter.pdf' target="_blank">Zur Startliste</a></td></tr>
                            <tr><td className='centered'>weibliche Jugend U17</td><td><a href='http://static.rad-net.de/html/bdr/meisterschaften/23-bdr/strasse/dm-pzf-wjugend-starter.pdf' target="_blank">Zur Startliste</a></td></tr>
                            <tr><td className='centered'>Schüler U15</td><td><a href='http://static.rad-net.de/html/bdr/meisterschaften/23-bdr/strasse/dm-mzf-schueler-starter.pdf' target="_blank">Zur Startliste</a></td></tr>
                            <tr><td className='centered'>Schülerinnen U15</td><td><a href='http://static.rad-net.de/html/bdr/meisterschaften/23-bdr/strasse/dm-pzf-schuelerinnen-starter.pdf' target="_blank">Zur Startliste</a></td></tr>
                        </table>
                        <table id='table'/>
                    </div>
                    <br/>
                    
                    <center>
                        Alle Startlisten können auf <a href="https://www.rad-net.de/meisterschaften_4.htm" target="_blank">rad-net</a> eingesehen werden.
                    </center>
                </div>                
            </div>
            <div className="contentShadow"></div>
        </div>
    )    
}

// <center>
// <select onChange={(e) => changeList(e.target.value)}>
// <option value=""/>
// <option value="U15m">Schüler U15</option>
// <option value="U15w">Schülerinnen U15</option>
// <option value="U17m">Jugend männlich U17</option>
// <option value="U17w">Jugend weiblich U17</option>
// <option value="JunM">Rad-Bundesliga Junioren</option>
// <option value="JunW">Rad-Bundesliga Juniorinnen</option>
// <option value="MaeM">Rad-Bundesliga Männer</option>
// <option value="FraW">Rad-Bundesliga Frauen</option>
// </select>
// </center> 

async function changeList(targetFile){
    console.log (targetFile)
    let list = await getContents(targetFile, window.innerWidth)
    document.getElementById('table').innerHTML(list[1])
    document.getElementById('download').href(list[0])
}

export default Startlisten;