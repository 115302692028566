import './footer.css'

function Footer(){
    return(
        <center>
            <div className='foot'>
                <div className='contentinFoot'>
                    <h5 className='underline'>Genthiner Radsportclub 66 e.V.</h5>
                    <div className='small'>
                        <div className='parallel'>
                            <div>
                                <span className='underline'>Social Media</span> <br></br>
                                <a className='silentLink' href='https://www.instagram.com/genthiner_radsportclub_66/' target='_blank'>Instagram @ genthiner_radsportclub_66</a>
                            </div>
                        </div>
                        <br></br>
                        Designed by Jan Jeschinski
                    </div>
                </div>
            </div>
        </center>
    )
}

export default Footer;