import './css/track.css';

function Track (){
    return(
        <div>
            <div className="contentContainer">
                <h1 id="title"> Spee Cup <br></br> Strecke </h1>
                <div className="trackChooseContainer">
                    <button className="trackChooser" onClick={() => setRoute(10)}>10 km</button>
                    <button className="trackChooser" onClick={() => setRoute(20)}>20 km / 40 km (2 Runden)</button>
                    <button className="trackChooser" onClick={() => setRoute(25)}>25 km / 50 km (2 Runden)</button>
                </div>
                <iframe src='' title="Strecke" className="map" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" id='map' ></iframe>
                <div className="altiFrame" id="altText">Bitte wähle eine Distanz aus, um die Strecke anzuzeigen.</div>
            </div>
            <div className="contentShadow"></div>
        </div>
    )
}

function setRoute(track){
    var cont;
    var dest = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d608.5096444623443!2d12.1635038405621!3d52.40597703803724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a8b56852107119%3A0x6290f52d7ecea87f!2sBrandenburger%20Str.%2097%2C%2039307%20Genthin!5e0!3m2!1sde!2sde!4v1690804715074!5m2!1sde!2sde";
    if (track===10) {
        dest = "https://www.google.com/maps/embed?pb=!1m40!1m12!1m3!1d1217.0019320807146!2d12.16373595463049!3d52.40660527006047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m25!3e1!4m3!3m2!1d52.405823299999994!2d12.16391!4m3!3m2!1d52.4064511!2d12.1650294!4m3!3m2!1d52.4067911!2d12.1589923!4m3!3m2!1d52.411723699999996!2d12.2280166!4m3!3m2!1d52.409140799999996!2d12.189995999999999!4m3!3m2!1d52.405755799999994!2d12.1637273!5e0!3m2!1sde!2sde!4v1691582188329!5m2!1sde!2sde";
        cont = "<l class='emphasized'>10 km</l> Strecke";
    } else if (track===20) {
        dest = "https://www.google.com/maps/embed?pb=!1m34!1m12!1m3!1d3693.948711329933!2d12.311994258164024!3d52.41130738410516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m19!3e0!4m3!3m2!1d52.405834999999996!2d12.163903999999999!4m3!3m2!1d52.411026899999996!2d12.304402399999999!4m3!3m2!1d52.4067935!2d12.159010499999999!4m5!1s0x47a8b568f8f15555%3A0x51674674600a5d0c!2sBerliner%20Ch%201%2C%2039307%20Genthin!3m2!1d52.4059289!2d12.1638415!5e0!3m2!1sde!2sde!4v1691582948442!5m2!1sde!2sde";
        cont = "<l class='emphasized'>20 km / 40 km (2 Runden)</l> Strecke";
    } else if (track===25) {
        dest = "https://www.google.com/maps/embed?pb=!1m34!1m12!1m3!1d10448.707318728599!2d12.25628829710536!3d52.40859379668748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m19!3e0!4m3!3m2!1d52.405834999999996!2d12.163903999999999!4m3!3m2!1d52.4106689!2d12.3410028!4m3!3m2!1d52.4067935!2d12.159010499999999!4m5!1s0x47a8b568f8f15555%3A0x51674674600a5d0c!2sBerliner%20Ch%201%2C%2039307%20Genthin!3m2!1d52.4059289!2d12.1638415!5e0!3m2!1sde!2sde!4v1691583012742!5m2!1sde!2sde";
        cont = "<l class='emphasized'>25 km / 50 km (2 Runden)</l> Strecke";
    }   
    document.getElementById('map').setAttribute("src",dest);
    document.getElementById('title').innerHTML= "Spee Cup <br>"+cont;
    document.getElementById('altText').style.display = 'none';
}

export default Track;