import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, HashRouter} from "react-router-dom";
import './index.css';
import Navigation from './navandfoot/navigation';
import Footer from './navandfoot/footer';
import Information from './siteContent/Information';
import Home from './siteContent/home'
import Tender from './siteContent/tende';
import Contact from './siteContent/contact';
import Track from './siteContent/track';
import Startlisten from './siteContent/startlists';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Navigation />
    <HashRouter>
        <Routes>
          <Route path="/" element={<Home />}exact  />
          <Route path="/track" element={<Track />}exact  />
          <Route path="/tender" element={<Tender />}exact  />
          <Route path="/about" element={<Startlisten />}exact />
          <Route path="/contact" element={<Contact />}exact  />
        </Routes>
      </HashRouter>
    <Footer />
  </React.StrictMode>
);
