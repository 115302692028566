
function Contact(){
    return(
        <div>
            <div className="contentContainer">
                <h1 id="title" className="centered"> Kontakt </h1>
                <div className="contentCenter">
                    <table className="autoWidth">
                        <tr>
                            <th colspan="2">Genthiner RC 66 e.V.</th>
                        </tr>
                        <tr>
                            <td><b>Straße:</b></td>
                            <td> Seedorfer Weg 51</td>
                        </tr>
                        <tr>
                            <td><b>Ort:</b> </td>
                            <td>39307 Genthin </td>
                        </tr>
                        <tr>
                            <td><b>Telefon:</b> </td>
                            <td>0171/6847193 </td>
                        </tr>
                        <tr>
                            <td><b>E-Mail:</b> </td>
                            <td><a href="mailto:genthiner.rc.66@gmail.com">genthiner.rc.66@gmail.com</a> </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className="contentShadow"></div>
        </div>
    )
}

export default Contact;