
function Home(){
    return(
        <div>
            <div className="contentContainer">
				<h1 id="title"> Home </h1>
				<div className="content">
					Am 3. September 2023 richtet der Genthiner Radsportclub 66 e.V. den 32. Spee Cup aus.<br></br><br></br>
				    Es fahren folgende Altersklasen auf den Strecken in den Disziplinen:
					 <ul>
						<li>Zeitfahren</li>
						<ul>
							<li>20km: Rad-Bundesliga Juniorinnen</li>
						</ul>
						<li>Paarzeitfahren</li>
						<ul>
							<li>10km: Sch&uuml;lerinnen U15</li>
							<li>20km: Jugend weiblich U17</li>
						</ul>
						<li>Mannschaftszeitfahren</li>
						<ul>
							<li>20km: Sch&uuml;ler U15 und Jugend m&auml;nnlich U17 </li>
							<li>40km: Rad-Bundesliga Frauen</li>
							<li>50km: Rad-Bundesliga Junioren, Rad-Bundesliga M&auml;nner und CT und Elite-Amateur</li>
						</ul>	

					 </ul>
					Details zu <a href="https://grc66.de/#/tender">Ausschreibung</a>, <a href="https://grc66.de/#/track">Streckenverlauf</a>,  <a href="https://grc66.de/#/about">Startzeiten und Startlisten</a> k&ouml;nnt ihr in den jeweiligen Reitern entnehmen.<br></br><br></br>
					
				</div>
				<br></br>
			</div>
			<div className="contentShadow"></div>
		</div>
    )
}

export default Home;
