import './navigation.css'
import {HashRouter, Link} from "react-router-dom";

function Navigation(){
    return(
        <div className="main">
			<div className="ham-container">
                <input type="checkbox" className="checkbox"></input>
                <div className="hamburger-lines">
                    <span className="line line1"></span>
                    <span className="line line2"></span>
                    <span className="line line3"></span>
                </div>
                <div className="logoBox">
                    <img src="images/grc-logo.png" alt="GRC Logo" className="logo"></img>
                </div>
                <div className="spacer"></div>
                <div className="navContainer">
                <HashRouter>
                    <Link to={""} onClick="window.location.reload();" className="ref">
                        <div className="navItem inactive" id="home">
                            Home
                        </div>
                    </Link>
                    <Link to={"track"} onClick="window.location.reload();" className="ref">
                        <div className="navItem inactive">
                            Strecke
                        </div>
                    </Link>
                    <Link to={"tender"} onClick="window.location.reload();" className="ref">
                        <div className="navItem inactive">
                            Ausschreibung
                        </div>
                    </Link>
                    <Link to={"about"} onClick="window.location.reload();" className="ref">
                        <div className="navItem inactive">
                            Startlisten
                        </div>
                    </Link>
                    <Link to={"contact"} onClick="window.location.reload();" className="ref">
                        <div className="navItem inactive">
                            Kontakt
                        </div>
                    </Link>
                </HashRouter>
                </div>
            </div>
        </div>
    )
}

export default Navigation;