function Tender(){
    return(
        <div>
            <div className="contentContainer">
                <h1 id="title"> Ausschreibung </h1>
                <div className="content">
                    Das Rennen ist ausgeschrieben.<br></br>
                    Details zur Ausschreibung k&ouml;nnen auf <a target="_blank" href="https://www.rad-net.de/rad-net-portal/ausschreibungen_1.htm?url=%2Fmodules.php%3Fname%3DAusschreibung%26rnswp_disziplin%3D1%26ID_Veranstaltung%3D38666%26mode%3Dascr_detail%26typ%3Di&url_hash=Q3R6RRQw66rfBoxbMXgdo3ymQJTi8o41Rcpgr5DgwKk">rad-net.de</a> entnommen werden.
                </div>
            </div>
            <div className="contentShadow"></div>
        </div>
    )
}

export default Tender;